import React from 'react';
import { IBuildRoutesCategoryTabProps } from './types';
import { functions } from '@wap-client/core';
import Icon from '@/components/base/icon';
import { motion } from 'framer-motion';

const BuildRoutesCategoryTab: React.FC<IBuildRoutesCategoryTabProps> = ({
  motion: motionProps,
  id,
  className,
  children,
  onClick,
  afterIcon,
  beforeIcon,
  iconSize = 16,
}) => {
  return (
    <motion.button
      className={functions.classnames('build-routes-category-tab', className)}
      id={id}
      onClick={onClick}
      {...motionProps}
    >
      {beforeIcon && <Icon name={beforeIcon} size={iconSize} />}
      <span className="category-tab-text">{children}</span>
      {afterIcon && <Icon name={afterIcon} size={iconSize} />}
    </motion.button>
  );
};

export default BuildRoutesCategoryTab;
